import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';

import HaarImg from './images/haar.png';
import CurioImg from './images/curiocollage.png';
import EartidoImg from './images/eartido.png';

const cards = [
    {
        cardTitle: 'Haar app',
        cardImage: HaarImg,
        cardContent: `A random, procedurally generated 3D maze which can be explored while listening to composer Fraser A Campbell's Haar series of albums. The music is sonically manipulated depending on the user's position in the maze`,
        url: 'https://apps.apple.com/us/app/haar/id1578880255'
    },
    {
        cardTitle: 'eartido!',
        cardImage: EartidoImg,
        cardContent: `eartido! is designed to compliment your child’s music lessons in and out of the classroom in order to develop musical comprehension, facilitate greater creativity and increase self-confidence within the expressive arts!`,
        url: 'https://www.eartido.com'
    },
    {
        cardTitle: 'Curio Collage',
        cardImage: CurioImg,
        cardContent: `A video wall that allows the user to manipulate playback and create their own composition, both visually and musically`,
        url: 'https://apps.apple.com/us/app/curio-collage/id1586493610'
    }
];

export default function ClickableCard() {
  return (
    <div className="cards">
    <Grid container maxWidth='80vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' sx={{marginBottom:'2rem'}} >
    {cards.map((card) => (
        <Grid item xs={3} justifyContent='center' sx={{ flexGrow: 3}}>
    <Card sx={{ maxWidth: '20rem' }}>
      <CardActionArea href={card.url}>
        <CardMedia
          component="img"
          width= '100%'
          image={card.cardImage}
          alt={card.cardTitle}
        />
        <CardContent sx={{backgroundColor: 'transparent'}}>
          <Typography gutterBottom variant="h5" component="div">
            {card.cardTitle}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {card.cardContent}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </Grid>
    ))}
    </Grid>
    </div>
    )
};