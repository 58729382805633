import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import './App.css';
import ClickableCard from './ClickCard.js';
import ResponsiveAppBar from './AppBar.js';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import { Routes, Route, Link } from "react-router-dom";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

function Home() {
  return(
  <div className="App-container">
        <div className="titleTxt">    
      <Typography variant='h4'>Welcome to Faug Productions, creators of the following apps:</Typography>
      </div>
      <ClickableCard  />
  </div>
  );
}
function PP() {
  return(
<div className="App-container">  
  <div className="titleTxt">   
    <Typography variant='h3'>Privacy Policy</Typography> 
    <Typography variant='h4'>This app does not knowingly take or use any of your data at any time.</Typography>
    <Typography variant='h5'>For more information, please contact us.</Typography>
  </div>
</div>
  );
}

function Main() {
  return(
    <Routes>       
      <Route path='/' element={<Home />} />
      <Route path='/pp' element={<PP />} />  
      <Route path="*" element={<NoMatch />} />    
    </Routes>
  );
}

function NoMatch() {
  return (
    <div className="App-container">
    <div className="titleTxt">
      <Typography variant='h3'>Nothing to see here!</Typography>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
    </div>
  );
}

function App() {  

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  let theme = React.useMemo(
      () => createTheme({
        palette: {
            mode: prefersDarkMode ? 'dark' : 'light',
          },
        }),
      [prefersDarkMode],
    );

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="App" >
    <ResponsiveAppBar />
      <Main />
    </div>
    </ThemeProvider>
  );
}

export default App;
